<template>
  <div class="graduation_student_list_page">
    <myTable title="转校学员列表" :list="list" :columns="columns">
      <template slot="top_block">
        <div class="code_block">
          <el-select v-model="selectValue" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="keyWord"
            style="width: auto; margin-left: 0.2rem"
            type="text"
            placeholder="请输入学生姓名搜索"
          ></el-input>
          <input
            style="display:block;width:0px;height:0px;opacity:0;"
            placeholder=""
            resize="none"
          />
          <el-button @click="search" class="table_top_btn_default"
            >搜索</el-button
          >
        </div>
      </template>
      <template slot="btns" slot-scope="item">
        <div class="table_btns">
          <div class="table_btn" @click="handleView(item.data.row, 'view')">
            查看
          </div>
          <div class="table_btn" @click="handleView(item.data.row), 'recove'">
            恢复
          </div>
        </div>
      </template>
    </myTable>
    <comViewDialog
      :list="fromList"
      ref="comdialog"
      :params.sync="stuInfo"
      title="查看"
    ></comViewDialog>
    <comPagination :params="params" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
import myTable from "@/components/admin/table.vue";
import comViewDialog from "@/components/admin/viewDialog.vue";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "graduationStudentList",
  components: {
    comPagination,
    comViewDialog,
    myTable,
  },
  data() {
    return {
      selectValue: "",
      options: [
        {
          label: "2021年",
          value: 1,
        },
      ],
      columns: [
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "sex",
          label: "性别",
        },
        {
          prop: "gradeName",
          label: "班级",
        },
        {
          prop: "integral",
          label: "积分",
        },
        {
          prop: "",
          label: "所转学校",
        },
      ],
      list: [],
      fromList: [
        {
          prop: "name",
          type: "text",
          label: "学生姓名",
        },
        {
          prop: "idCardType",
          type: "radio",
          label: "证件类型",
        },
        {
          prop: "idCard",
          type: "text",
          label: "证件号",
        },
        {
          prop: "parentName",
          type: "text",
          label: "家长姓名",
        },
        {
          prop: "photo",
          type: "img",
          label: "学员照片",
        },
        {
          prop: "birthday",
          type: "date",
          label: "学员出生日期",
        },
        {
          prop: "gradeName",
          type: "select",
          label: "学员所属班级",
        },
        {
          prop: "sex",
          type: "radio",
          label: "学员性别",
          options: [
            {
              label: "男",
              value: 1,
            },
            {
              label: "女",
              value: 2,
            },
          ],
        },
        {
          prop: "phone",
          type: "number",
          label: "家长联系电话",
        },
      ],
      stuInfo: {
        name: "",
        idCardType: 0,
        idCard: "",
        parentName: "",
        photo: "/uploads/20200325/3ae1c386e9df7a6ae1b0954cdc6feb11.jpg",
        birthday: "",
        gradeId: 3,
        sex: "",
        phone: "",
      },
      keyWord: "",
      //分页
      params: {
        page: 0,
        size: 10,
        total: 0,
      },
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    //初始化
    init() {
      this.getInfo();
    },
    search() {
      this.params.page = 1;
      this.params.size = 10;
      this.getInfo();
    },
    getInfo() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        name: this.keyWord,
        type: 1,
      };
      // this.list = [];
      this.api.admin.findChangeStuList(this.params, data).then((res) => {
        //   res.data.id = this.$route.query.id
        //   this.list.push(res.data)
        this.list = res.data.list.map((item) => {
          return {
            ...item,
            sex: item.sex == 1 ? "男" : "女",
          };
        });

        this.params.total = res.data.total;
        console.log(res);
      });
    },
    handleView(val, type) {
      console.log(val);
      if (type === "view") {
        this.$refs.comdialog.isOpen();
        this.stuInfo = {
          name: val.name,
          idCardType: val.idCardType == 0 ? '身份证' : val.idCardType == 1 ? '护照' : '其他',
          idCard: val.idCard,
          parentName: val.parentName,
          photo: val.photo,
          birthday: val.birthday,
          gradeName: val.gradeName,
          sex: val.sex,
          phone: val.phone,
        };
      } else {
        this.$confirm("是否确认恢复?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let data = {
              type: 0,
              beforeGrade: 0,
              gradeId: val.beforeGrade,
            };
            this.api.admin.changeSchool(data, val.id).then;
            this.$notice({
              type: "success",
              message: "恢复成功!",
            }).isShow();
            this.$router.go(0);
          })
          .catch(() => {
            this.$notice({
              type: "info",
              message: "已取消恢复",
            }).isShow();
          });
      }
    },
    //分页 todo 待测试
    changePage(val) {
      this.params.page = val;
      this.getInfo();
    },
  },
};
</script>

<style lang="scss">
.graduation_student_list_page {
  .code_block {
    @include flex(row, flex-end, center);

    .el-input__inner {
      height: 60px;
      width: 250px;
      font-size: 20px;
    }
  }
}
</style>